<template>
  <form class="v-components accounts-update-form" @submit.prevent="submit">
    <b-field grouped>
      <b-field
        label="Status"
        :type="{'is-danger': errors.has('status')}"
        :message="errors.first('status')">

        <b-select
          :disabled="currentAccount()"
          data-vv-as="Status"
          name='status'
          v-model="formData.status"
          v-validate="'integer'"
          placeholder="Select">

          <option
            v-for="(status, key) in formValues.status"
            :value="status.code"
            :key="key">

            {{ status.name }}
          </option>
        </b-select>
      </b-field>

      <b-field
        label="Email"
        :type="{'is-danger': errors.has('email')}"
        :message="errors.first('email')">

        <b-input type="email"
          v-model="formData.email"
          name="email"
          data-vv-as="Email"
          v-validate="'required|email'"
          min="1">
        </b-input>
      </b-field>

      <b-field
        label="New Password"
        :type="{'is-danger': errors.has('password')}"
        :message="errors.first('password')">

        <b-input
          v-model="formData.password"
          name="password"
          v-validate="'min:8'"
          ref="password"
          type="password">
        </b-input>
      </b-field>

      <b-field
        label="Confirm Password"
        :type="{'is-danger': errors.has('confirm_password')}"
        :message="errors.first('confirm_password')">

        <b-input
          data-vv-as="Confirm Password"
          v-model="formData.confirm_password"
          name="confirm_password"
          v-validate="'confirmed:password'"
          type="password">
        </b-input>
      </b-field>
    </b-field>

    <b-field grouped>
      <b-field
        label="Name"
        :type="{'is-danger': errors.has('name')}"
        :message="errors.first('name')">

        <b-input
          type="text"
          v-model="formData.name"
          name="name"
          data-vv-as="Name"
          v-validate="'required'">
        </b-input>
      </b-field>
    </b-field>

    <div class="notification">
      <div class="buttons">
        <b-button
          type="is-success"
          tag="input"
          native-type="submit"
          value="Update">
        </b-button>

        <b-button tag="router-link"
          :to="'/accounts'"
          type="is-danger"
          icon-left="cancel"
          outlined>
          Cancel
        </b-button>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  name: 'AccountUpdateForm',
  props: {
    account: {
      type: Object,
      default: function() {
        return {}
      },
    },
    afterRedirect: {
      type: Object,
      default: function() {
        return {}
      },
    },
  },
  data: function() {
    return {
      connection_error: false,

      formData: {
        name: null,
        email: null,
        password: null,
        confirm_password: null,
        status: null,
      },
      formValues: {
        status: [
          {
            code: 1,
            name: 'Active',
          },
          {
            code: 2,
            name: 'Blocked',
          },
          {
            code: 3,
            name: 'Unverified',
          },
        ]
      },
    }
  },
  methods: {
    updateData: async function() {
      const thisCompo = this

      const loading = thisCompo.$buefy.loading.open()

      const newData = {
        id: this.formData.id,
        name: this.formData.name,
        email: this.formData.email,
      };

      if (this.formData.status) {
        newData.status = this.formData.status
      }
      if (this.formData.password) {
        newData.password = this.formData.password
      }

      const updated = await this.$api.accountsUpdate(newData).catch( (err) => {
        if (err.response) {
          thisCompo.$buefy.toast.open({
            message: err.response.data.message,
            type: 'is-danger',
            position: 'is-top'
          })
        }
        else {
          thisCompo.$buefy.toast.open({
            message: 'Connection error',
            type: 'is-danger',
            position: 'is-bottom'
          })
        }

        loading.close()
      })

      if (updated) {
        loading.close()

        if (this.afterRedirect) {
          this.$router.push(this.afterRedirect);
        }

        this.$buefy.toast.open({
          message: 'Successfully Updated',
          type: 'is-success',
          position: 'is-top'
        })

        if (this.currentAccount()) {
          this.$api.accountsList({
            id: newData.id
          }).then((response) => {
            if (response.data.list.length) {
              thisCompo.$store.dispatch('User/set_account', response.data.list[0])
            }
          })
        }
      }
    },
    submit: function() {
      const thisCompo = this

      this.$validator.validateAll().then(result => {
        if (result) {
          thisCompo.updateData()
        }
        else {
          thisCompo.$buefy.toast.open({
            message: 'Form is not valid! Please check the fields.',
            type: 'is-danger',
            position: 'is-bottom'
          })
        }
      })
    },
    currentAccount: function() {
      return this.account._id == this.$store.state.User.account._id
    }
  },
  watch: {
    account: {
      immediate: true,
      handler: function(newVal) {
        this.formData.id = newVal._id
        this.formData.name = newVal.name
        this.formData.email = newVal.email

        if (!this.currentAccount()) {
          this.formData.status = newVal.status
        }
      },
    },
  },
}
</script>
